import { GetStaticProps } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useEffect } from 'react'

import { SSRConfig } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Layout from '@/components/Layout'

function Custom404() {
  const router = useRouter()

  useEffect(() => {
    const timer = setTimeout(() => {
      router.push('/')
    }, 5000)

    return () => clearTimeout(timer)
  }, [router])

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-600 mb-8">
          페이지를 찾을 수 없습니다
        </h2>
        <p className="text-gray-500 mb-8">
          죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 5초 후 자동으로
          홈페이지로 이동합니다.
        </p>
        <Link
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          href="/">
          홈으로 돌아가기
        </Link>
      </div>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps<SSRConfig> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Custom404
